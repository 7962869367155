@font-face {
  font-family: Bungee;
  src: url(Bungee-Regular.ttf);
  font-style: normal;
  font-weight: 100;
}

body {
  background: url('../../public/assets/background.jpg') no-repeat center center
    fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  background-size: cover;
  -o-background-size: cover;
}

img {
  border-radius: 16px;
  box-shadow: rgba(0, 0, 0, 0.8);
  width: 300px;
  height: 300px;
  object-fit: scale-down;
}

@media only screen and (max-width: 480px) {
  img {
    height: 30%;
    width: 30%;
  }
}

.title {
  margin-top: 3vh;
  margin-bottom: 5vh;
  font-family: 'Bungee';
}

/* CSS */
.RollBtn {
  margin-bottom: 7vh;
  font-family: 'Bungee';
  background-image: linear-gradient(#464d55, #25292e);
  border-radius: 8px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1), 0 3px 6px rgba(0, 0, 0, 0.05);
  color: #fff;
  cursor: pointer;
  min-height: 5vh;
  min-width: 16vw;
  line-height: 1;
  outline: none;
  overflow: hidden;
  transform: translate3d(0, 0, 0);
  transition: all 150ms;
  vertical-align: baseline;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  font-size: 3vh;
}
.RollBtn:disabled {
  pointer-events: none;
}

.RollBtn:hover {
  box-shadow: rgba(0, 0, 0, 0.25) 0 8px 15px;
  transform: translateY(-2px);
}

.RollBtn:active {
  box-shadow: none;
  transform: translateY(0);
}

h4 {
  font-family: 'Bungee';
}
h3 {
  font-family: 'Bungee';
  text-decoration: underline;
}
.modalButton {
  float: right;
  background-image: linear-gradient(#464d55, #25292e);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1), 0 3px 6px rgba(0, 0, 0, 0.05);
  font-family: 'Bungee';
  margin-bottom: 7vh;
  cursor: pointer;
}
.menuViiva {
  width: 35px;
  height: 5px;
  background-color: white;
  margin: 6px 0;
  cursor: pointer;
}
.modalButton:disabled {
  pointer-events: none;
}

.modalButton:hover {
  box-shadow: rgba(0, 0, 0, 0.25) 0 8px 15px;
  transform: translateY(-2px);
}

.modalButton:active {
  box-shadow: none;
  transform: translateY(0);
}

.modalButton:active {
  box-shadow: none;
  transform: translateY(0);
}

@media only screen and (max-width: 480px) {
  .menuViiva {
    width: 15px;
    height: 2px;
  }
}
