.overlay {
  position: fixed;
  width: 100%;
  height: 100%;
}

.modalContainer {
  max-width: 600px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  background-color: rgba(185, 185, 185, 0.9);
  border-radius: 8px;
}

.popupImg {
  margin-left: auto;
  margin-right: auto;
}

.btnContainer {
  display: flex;
  padding: 1rem 1rem;
}
.btnContainer button {
  background-color: crimson;
  border: none;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 7vh;
  font-family: 'Bungee';
  background-image: linear-gradient(crimson, red);
  border-radius: 8px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1), 0 3px 6px rgba(0, 0, 0, 0.05);
  color: #fff;
  cursor: pointer;
  min-height: 5vh;
  min-width: 16vw;
  line-height: 1;
  outline: none;
  overflow: hidden;
  transform: translate3d(0, 0, 0);
  transition: all 150ms;
  vertical-align: baseline;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  font-size: 3vh;
  border: solid white 0.1px;
}

.bold {
  font-weight: 600;
}
p {
  font-size: 18px;
}

@media screen and (max-width: 500px) {
  .modalContainer {
    top: 55%;
    overflow: auto;
  }
}

@media screen and (max-width: 500px) {
  img {
    max-width: 350px;
  }
}
